import React, {Component} from 'react'
import Layout from 'components/Layout/Layout'
import {navigate, StaticQuery, graphql} from 'gatsby'
import styled from "styled-components";
import BgImage from '../components/shared/BgImage'
import {GatsbyImage} from "gatsby-plugin-image";

const IframeWrapper = styled.div`
    height: 100vh;
    position: relative;
    width: 100%;

    h1 {
    	visibility: hidden;
    }
    
`

const BottomOfIframe = styled.div`
    position: absolute;
    top: 75%;

    @media (min-width: 992px) {
        top: 100%;
    }
`

const Content = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 642px;
    position: relative;
    z-index: 2;
    color: ${props => props.theme.colors.white};
    text-align: center;
    padding: 0 0 0 1rem;
    margin: 0 auto;
    
    .scroll-helper {
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
       
    }
    h2 {
        font-size: 1.5rem;
    }
    
    p {
        font-size: 1.25rem;
        font-family: 'Proxima Nova W05 Bold', Arial, sans-serif;
        font-weight: bold;
    }

  .btn--trailer {
    width: 200px;
    height: 40px;
  }
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "575x960-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                FallbackSm: file(relativePath: { eq: "991x1320-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                FallbackLg: file(relativePath: { eq: "1200x720-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
                
                FallbackXl: file(relativePath: { eq: "1920x1080-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                logo: file(relativePath: { eq: "Logo_lrg.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 800, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Interstitial props={props} data={data}/>
        )}
    />
)

class Interstitial extends Component {

    constructor(props) {
        super(props)

        this.state = {
            href: "",
        }

        this.cancelRedirect = this.cancelRedirect.bind(this)
    }

    componentWillUnmount() {
        document.body.classList.remove('hide-disney-header');
    }

    componentDidMount() {
        document.body.classList.add('hide-disney-header');

        if (this.props.props.location.state === null || typeof this.props.props.location.state === undefined) {
            navigate("/");
            return true
        }
        const {href} = this.props.props.location.state

        this.setState({
            href,
        })
    }

    cancelRedirect() {
        clearTimeout(this.redirect)
    }

    dcfTracking() {
        if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
          window.__dataLayer.page.page_name = `emea:uk:disney:frozenthemusical:${this.props.pageName}`;
          window.__dataLayer.trackPage()
          console.log('Tracking is set')
        }
      }

    render() {

        const {href} = this.state
        if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
            const eventData = {
                event_name: "exit-link",
                actionValue: href
            }
            window.__dataLayer.publish('exit-link', eventData)
        }
        if (href !== "") {

            return (
                <Layout pageName="interstitial" interstitial={true} slug="interstitial" title="An Important Message">
                    <IframeWrapper>
                        <h1 className="sr-only">Frozen the Musical</h1>
                        <BgImage
                            data={this.props.data}
                        />
                        <BottomOfIframe id="bottomOfIframe"/>
                        <Content>
                            <div className="scroll-helper">
                                <div className="logo">
                                    <GatsbyImage image={this.props.data.logo.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="d-block img-fluid"
                                    />
                                </div>
                                <h2>PLEASE NOTE</h2>

                                <p>Disney privacy practices and controls do not apply to the site you are about to
                                    visit, as it is not controlled by Disney. Please click below to say that you are
                                    happy to continue.</p>

                                <div className="d-block d-sm-flex" style={{
                                    paddingBottom: '4rem'
                                }}>
                                    <button onClick={() => navigate(-1)} className="btn btn--trailer btn--interstitial-back my-2 my-sm-0 mx-auto d-none d-sm-block"><span>Go
                                        Back</span></button>
                                    <a href={href} className="btn btn--new my-2 my-sm-0 mx-auto"><span>Continue</span></a>
                                    <button onClick={() => navigate(-1)} className="btn btn--trailer btn--interstitial-back my-2 my-sm-0 mx-auto d-sm-none"><span>Go
                                        Back</span></button>
                                </div>
                            </div>
                        </Content>
                    </IframeWrapper>
                </Layout>
            )
        } else {
            return <></>
        }

    }

}

export default Query
